import React, { useMemo } from 'react';
import HDPTable, { Header } from 'components/HDPTable/HDPTable';
import { TABLE_PARAMS_IDS } from 'constants/constants';
import useTableParams from 'hooks/useTableParams/useTableParams';
import { DateTime } from 'luxon';

import Filters from '../Filters/Filters';
import GracefulFailuresDownload from '../GracefulFailuresDownload';
import SubmissionLink from '../SubmissionLink';
import { filterSubmissions, SubmissionHydrated } from '../utils';

import AwaitingActionMenu from './AwaitingActionMenu';

import styles from './submissionSummaryTable.module.scss';

export type DatumMappedToHeaders = {
    id: string;
    provider: string;
    'current-state': React.ReactElement;
    'current-step': string | undefined;
    'furthest-step': string | undefined;
    'last-submission': string | undefined;
    'student-count': number | undefined;
    'open-issues': number | undefined;
    'awaiting-action': React.ReactElement;
};

const getTableRows = (
    rawData: SubmissionHydrated[],
): DatumMappedToHeaders[] => {
    const formatDate = (date: string | undefined): string | undefined =>
        date ? DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm') : undefined;

    /* Each row of the table should be an object with keys corresponding to the headers.
     * The keys should be the same as the id of the header.
     * The value of each key should be the value to display in the cell.
     *
     * For this table we want:
     * - ID: The ID of the submission
     * - Provider: The name of the provider
     * - Current submission state: The current state of the submission (with a link to the state, along with a download button for the error log)
     * - Current step: The current step of the submission (out of total steps)
     * - Furthest step: The furthest step the submission has reached (out of total steps)
     * - Last submission: The datetime of the last submission
     * - Student count: The number of students in the submission
     * - Open issues: The number of open issues in the submission
     * - Awaiting action: The number of issues awaiting action in the submission
     */

    const dataMappedToHeaders: DatumMappedToHeaders[] = rawData.map(
        (submission: SubmissionHydrated) => ({
            id: submission.provider.institutionId,
            provider: submission.provider.name,
            'current-state': (
                <div className={styles.state}>
                    <SubmissionLink
                        collection={submission.collection}
                        submission={submission.latestSubmission}
                        isNilReturn={false}
                        institutionId={submission.provider.institutionId}
                        isHistoricResubmission={false}
                        isOvt={false}
                    />
                    <GracefulFailuresDownload
                        latestSubmission={submission.latestSubmission}
                    />
                </div>
            ),
            'current-step': submission.latestSubmission?.status?.step?.number
                ? `${submission.latestSubmission.status.step.number} / ${submission.totalSteps}`
                : undefined,
            'furthest-step': submission.furthestSubmission?.status?.step?.number
                ? `${submission.furthestSubmission.status.step.number} / ${submission.totalSteps}`
                : undefined,
            'last-submission': formatDate(
                submission.latestSubmission?.uploaded,
            ),
            'student-count': submission.latestSubmission?.studentCount
                ? submission.latestSubmission.studentCount
                : undefined,
            'open-issues': submission.latestSubmission
                ? submission.provider.issueCount
                : undefined,
            'awaiting-action': (
                <div className={styles.state}>
                    {submission.awaitingParty?.name
                        ? submission.awaitingParty?.name
                        : ''}
                    {Object.values(submission.actionStates).some(
                        state => state,
                    ) && (
                        <AwaitingActionMenu
                            latestSubmission={submission.latestSubmission}
                            provider={submission.provider}
                            collection={submission.collection}
                            actionStates={submission.actionStates}
                        />
                    )}
                </div>
            ),
        }),
    );
    return dataMappedToHeaders;
};

const sortData = (
    dataMappedToHeaders: DatumMappedToHeaders[],
    sortBy: keyof DatumMappedToHeaders,
    sortOrder: 'asc' | 'desc',
    offset: number,
    limit: number,
) => {
    switch (sortBy) {
        // Sort progress by the current step **number** (not name)
        case 'current-step':
            dataMappedToHeaders.sort((a, b) => {
                // if undefined, sort it to the end of the list in both cases
                if (
                    a['current-step'] === undefined ||
                    a['current-step'] === null
                ) {
                    return 1;
                }

                if (
                    b['current-step'] === undefined ||
                    b['current-step'] === null
                ) {
                    return -1;
                }

                const aProgress = parseInt(a['current-step'].split(' /')[0]);

                const bProgress = parseInt(b['current-step'].split(' /')[0]);

                return sortOrder === 'asc'
                    ? aProgress - bProgress
                    : bProgress - aProgress;
            });
            break;
        case 'furthest-step':
            dataMappedToHeaders.sort((a, b) => {
                // if undefined, sort it to the end of the list in both cases
                if (
                    a['furthest-step'] === undefined ||
                    a['furthest-step'] === null
                ) {
                    return 1;
                }

                if (
                    b['furthest-step'] === undefined ||
                    b['furthest-step'] === null
                ) {
                    return -1;
                }

                const aProgress = parseInt(a['furthest-step'].split(' /')[0]);

                const bProgress = parseInt(b['furthest-step'].split(' /')[0]);

                return sortOrder === 'asc'
                    ? aProgress - bProgress
                    : bProgress - aProgress;
            });
            break;

        case 'last-submission':
            dataMappedToHeaders.sort((a, b) => {
                // if undefined, sort it to the end of the list in both cases
                const aDate = a['last-submission'];
                const bDate = b['last-submission'];
                if (aDate === undefined || aDate === null) {
                    return 1;
                }

                if (bDate === undefined || bDate === null) {
                    return -1;
                }

                const parsedDateA = DateTime.fromFormat(
                    aDate,
                    'dd/LL/yyyy HH:mm',
                ).toMillis();
                const parsedDateB = DateTime.fromFormat(
                    bDate,
                    'dd/LL/yyyy HH:mm',
                ).toMillis();

                return sortOrder === 'asc'
                    ? parsedDateA - parsedDateB
                    : parsedDateB - parsedDateA;
            });
            break;

        case 'awaiting-action':
            dataMappedToHeaders.sort((a, b) => {
                // awaiting-action is a react element consisting of two parts: the name of the party awaiting action and a menu of actions
                // We need to sort by the name of the party awaiting action
                const aVal = a['awaiting-action'].props.children[0];
                const bVal = b['awaiting-action'].props.children[0];

                // if the values are equal, sort by whether the submission has any actions to take
                if (aVal === bVal) {
                    const aHasActions =
                        a['awaiting-action'].props.children[1] !== false;
                    const bHasActions =
                        b['awaiting-action'].props.children[1] !== false;

                    if (sortOrder === 'asc') {
                        return (aHasActions ? 1 : 0) - (bHasActions ? 1 : 0);
                    } else {
                        return (bHasActions ? 1 : 0) - (aHasActions ? 1 : 0);
                    }
                }

                if (typeof aVal === 'string' && typeof bVal === 'string') {
                    return sortOrder === 'asc'
                        ? aVal.localeCompare(bVal)
                        : bVal.localeCompare(aVal);
                }

                return 0;
            });
            break;

        default:
            dataMappedToHeaders.sort(
                (a: DatumMappedToHeaders, b: DatumMappedToHeaders) => {
                    const aVal = a[sortBy];
                    const bVal = b[sortBy];

                    if (aVal === undefined || aVal === null) {
                        return 1;
                    }

                    if (bVal === undefined || bVal === null) {
                        return -1;
                    }

                    // if the value is a number, sort by number
                    if (typeof aVal === 'number' && typeof bVal === 'number') {
                        return sortOrder === 'asc' ? aVal - bVal : bVal - aVal;
                    }

                    // If it's a string, sort alphabetically
                    if (typeof aVal === 'string' && typeof bVal === 'string') {
                        return sortOrder === 'asc'
                            ? aVal.localeCompare(bVal)
                            : bVal.localeCompare(aVal);
                    }

                    // If not a string or a number, don't attempt to sort
                    return -1;
                },
            );
    }

    // Slice data. If limit is -1, show all data
    const slicedData = dataMappedToHeaders.slice(
        offset * limit,
        // ignore no-mixed-operators error as some other linting rule keeps removing the parentheses
        // eslint-disable-next-line no-mixed-operators
        limit === -1 ? dataMappedToHeaders.length : limit * offset + limit,
    );

    return slicedData;
};

const headers: Header<DatumMappedToHeaders>[] = [
    { id: 'id', label: 'ID', isSortable: false },
    { id: 'provider', label: 'Provider', isSortable: false },
    {
        id: 'current-state',
        label: 'Submission state',
        isSortable: false,
    },
    {
        id: 'current-step',
        label: 'Current Step',
        isSortable: true,
    },
    {
        id: 'furthest-step',
        label: 'Furthest Step',
        isSortable: true,
    },
    {
        id: 'last-submission',
        label: 'Last submission',
        isSortable: true,
    },
    {
        id: 'student-count',
        label: 'Student count',
        isSortable: true,
        alignment: 'centre',
    },
    {
        id: 'open-issues',
        label: 'Open issues',
        isSortable: true,
        alignment: 'centre',
    },
    {
        id: 'awaiting-action',
        label: 'Awaiting action',
        isSortable: true,
    },
];

interface SubmissionSummaryTableProps {
    dataFromApi: SubmissionHydrated[];
}
const SubmissionSummaryTable = ({
    dataFromApi,
}: SubmissionSummaryTableProps) => {
    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: -1,
        selectedProviders: [] as string[],
        selectedStatuses: [] as string[],
        selectedRegulators: [] as string[],
        selectedAwaitingAction: [] as string[],
        sortBy: 'last-submission' as keyof DatumMappedToHeaders,
        sortOrder: 'desc' as 'desc' | 'asc',
    };

    const tableId = TABLE_PARAMS_IDS.SUBMISSIONS_SUMMARY;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const {
        selectedProviders,
        selectedStatuses,
        selectedRegulators,
        selectedAwaitingAction,
        sortBy,
        sortOrder,
        offset,
        limit,
    } = tableParams;

    const {
        setSelectedAwaitingAction,
        setSelectedRegulators,
        setSelectedProviders,
        setSelectedStatuses,
        setSortBy,
        setSortOrder,
        setOffset,
    } = tableParamsMethods;

    const providers = dataFromApi.map(s => s.provider);

    const filteredSubmissions = useMemo(() => {
        setOffset(0);
        return filterSubmissions(
            dataFromApi,
            selectedProviders,
            selectedStatuses,
            selectedRegulators,
            selectedAwaitingAction,
        );
    }, [
        dataFromApi,
        selectedProviders,
        selectedStatuses,
        selectedRegulators,
        selectedAwaitingAction,
    ]);

    const transformedSubmissions = useMemo(
        () => getTableRows(filteredSubmissions),
        [filteredSubmissions],
    );

    const onClickSort = (id: keyof DatumMappedToHeaders) => {
        // if already sorting by the same column, flip the order
        if (sortBy === id) {
            const flippedSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
            setSortOrder(flippedSortOrder);
            return;
        }

        // otherwise, just set the sortBy column
        setSortBy(id);
    };

    const [dataToDisplay, setDataToDisplay] = React.useState(
        sortData(
            transformedSubmissions,

            sortBy,
            sortOrder,
            offset,
            limit,
        ),
    );

    // use useEffect to update the data to display when the data changes, when the sorting changes, or when the pagination changes
    React.useEffect(() => {
        // Would be better if we checked _which_ parameter changed, so we don't sort when just the limit has changed
        // but the current performance still seems fine

        setDataToDisplay(
            sortData(
                transformedSubmissions,

                sortBy,
                sortOrder,
                offset,
                limit,
            ),
        );
    }, [transformedSubmissions, sortBy, sortOrder, offset, limit]);

    return (
        <>
            <Filters
                providers={providers}
                submissions={dataFromApi}
                setSelectedProviders={setSelectedProviders}
                setSelectedAwaitingAction={setSelectedAwaitingAction}
                setSelectedRegulators={setSelectedRegulators}
                setSelectedStatuses={setSelectedStatuses}
                selectedProviders={selectedProviders}
                selectedStatuses={selectedStatuses}
                selectedRegulators={selectedRegulators}
                selectedAwaitingAction={selectedAwaitingAction}
            />
            <HDPTable<DatumMappedToHeaders>
                headers={headers}
                sorting={{
                    key: sortBy,
                    direction: sortOrder,
                }}
                onClickSort={onClickSort}
                data={dataToDisplay}
            />
        </>
    );
};

export default SubmissionSummaryTable;

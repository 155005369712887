import { Provider } from 'types/provider';

import * as filesApi from '../../../services/api/files';

export interface LatestSubmission {
    errorFileExists: boolean;
    errorFileName: string;
    fileHash: string;
    fileName: string;
    id: number;
    nextApprovalState: any | null; // TODO what is the correct type instead of any?
    riskCode: string;
    stateHistory: string[];
    status: {
        code: string;
        id: number;
        name: string;
        step: {
            id: number;
            name: string;
            number: number;
        };
    };
    studentCount: number;
    updated: string;
    uploaded: string;
    uuid: string;
}

export interface FurthestSubmission {
    status: {
        step: {
            id: number;
            name: string;
            number: number;
        };
    };
}

export interface ActionStates {
    canCreateNilReturn: boolean;
    canRequestResubmission: boolean;
    readyForHesaApproval: boolean;
    readyForScApproval: boolean;
    readyForSignOff: boolean;
}

export interface SubmissionCollection {
    id: number;
    name: string;
    reference: string;
    referencePeriodEnd: string;
    referencePeriodStart: string;
}

export interface AwaitingParty {
    id: string;
    name: string;
}
// Submission
export interface SubmissionHydrated {
    actionStates: ActionStates;
    uuid: string;
    provider: Provider;
    state: number;
    awaitingParty: AwaitingParty | null;
    collection: SubmissionCollection;
    furthestSubmission: FurthestSubmission;
    latestSubmission: LatestSubmission;
    totalSteps: number;
}

interface DownloadResponse {
    url: string;
}

export const requestGracefulFailuresDownload = async (
    submissionUuid: string,
): Promise<void> => {
    const { url }: DownloadResponse = await filesApi.downloadGracefulFailures(
        submissionUuid,
    );
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const filterSubmissions = (
    submissions: SubmissionHydrated[],
    selectedProviders: string[],
    selectedStatuses: string[],
    selectedRegulators: string[],
    selectedAwaitingAction?: string[],
): SubmissionHydrated[] => {
    const filteredSubmissions = submissions.filter(submission => {
        const providerMatch =
            selectedProviders.length === 0 ||
            selectedProviders.includes(submission.provider.institutionId);

        const statusMatch =
            selectedStatuses.length === 0 ||
            selectedStatuses.includes(submission.state.toString());

        const regulatorMatch =
            selectedRegulators.length === 0 ||
            selectedRegulators.includes(submission.provider.regulatorCode);

        // if filterSubmissions is being called in the OVT summary, we don't need to check the awaiting action
        // so we return here
        if (!selectedAwaitingAction) {
            return providerMatch && statusMatch && regulatorMatch;
        }

        // if filterSubmissions is being called in the non OVT summary, we also need to check the awaiting action
        const awaitingActionMatch =
            selectedAwaitingAction.length === 0 ||
            (submission.awaitingParty !== null &&
                selectedAwaitingAction.includes(submission.awaitingParty.id));

        return (
            providerMatch &&
            statusMatch &&
            regulatorMatch &&
            awaitingActionMatch
        );
    });
    return filteredSubmissions;
};

export const getNextTep = (
    stepName: string,
    displayStepMapping: { [key: string]: string },
) => {
    const stepNames = Object.keys(displayStepMapping);
    const stepIndex = stepNames.indexOf(stepName);
    // if the step is the last step, return empty string as there is no next step
    if (stepIndex === stepNames.length - 1) {
        return '';
    }
    return displayStepMapping[stepNames[stepIndex + 1]];
};

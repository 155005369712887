import React from 'react';
import GhostButton from 'components/Button/GhostButton';
import { Provider } from 'types/provider';

import { SubmissionHydrated } from '../utils';

import AwaitingActionsFilter from './AwaitingActionsFilter';
import ProviderFilter from './ProviderFilter';
import RegulatorFilter from './RegulatorFilter';
import StateFilter from './StateFilter';

import styles from './filters.module.scss';

interface FiltersProps {
    providers: Provider[];
    submissions: SubmissionHydrated[];
    setSelectedProviders: (value: string[]) => void;
    setSelectedStatuses: (value: string[]) => void;
    selectedProviders: string[];
    selectedStatuses: string[];
    selectedRegulators: string[];
    setSelectedRegulators: (value: string[]) => void;
    selectedAwaitingAction: string[];
    setSelectedAwaitingAction: (value: string[]) => void;
    isOvt?: boolean;
}

export default function Filters({
    providers,
    submissions,
    setSelectedProviders,
    setSelectedStatuses,
    selectedProviders,
    selectedStatuses,
    selectedRegulators,
    setSelectedRegulators,
    selectedAwaitingAction,
    setSelectedAwaitingAction,
    isOvt = false,
}: FiltersProps) {
    const handleClearAllFilters = () => {
        if (!isOvt) setSelectedAwaitingAction([]);
        setSelectedProviders([]);
        setSelectedRegulators([]);
        setSelectedStatuses([]);
    };

    const handleProviderChange = (_: React.SyntheticEvent, value: string[]) => {
        setSelectedProviders(value);
    };

    const handleStateChange = (_: React.SyntheticEvent, value: string[]) => {
        setSelectedStatuses(value);
    };

    const handleRegulatorChange = (
        _: React.SyntheticEvent,
        value: string[],
    ) => {
        setSelectedRegulators(value);
    };

    const handleAwaitingActionChange = (
        _: React.SyntheticEvent,
        value: string[],
    ) => {
        setSelectedAwaitingAction(value);
    };

    return (
        <div className={styles.filters}>
            <ProviderFilter
                providers={providers}
                onChange={handleProviderChange}
                value={selectedProviders}
            />
            <StateFilter
                submissions={submissions}
                onChange={handleStateChange}
                value={selectedStatuses}
            />
            <RegulatorFilter
                value={selectedRegulators}
                submissions={submissions}
                onChange={handleRegulatorChange}
            />
            {!isOvt && (
                <AwaitingActionsFilter
                    value={selectedAwaitingAction}
                    submissions={submissions}
                    onChange={handleAwaitingActionChange}
                />
            )}
            <GhostButton onClick={handleClearAllFilters}>
                Clear all filters
            </GhostButton>
        </div>
    );
}

import { useCallback, useMemo, useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { DialogContentText, Typography } from '@mui/material';
import { RestrictAccess } from 'src/components';
import ConfirmationPopup from 'src/components/ConfirmationPopup/ConfirmationPopup';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import {
    useProviderStatuses,
    useSubmissionSummaryActions,
} from '../../queries';
const approveAndDeclinePermissions = [
    'monitoring.hesa-approval',
    'monitoring.sc-approval',
];
const signOffPermissions = ['monitoring.sign-off-submission'];
const requestResubmissionPermissions = ['monitoring.request-resubmission'];
const nilReturnPermissions = ['monitoring.nil-return'];

import styles from './awaitingActionMenu.module.scss';

const menuItems = {
    approve: {
        permissions: approveAndDeclinePermissions,
        actionStatesRequired: ['readyForHesaApproval', 'readyForScApproval'],
        label: 'Approve',
    },
    decline: {
        permissions: approveAndDeclinePermissions,
        actionStatesRequired: ['readyForHesaApproval', 'readyForScApproval'],
        label: 'Decline',
    },
    signOff: {
        permissions: signOffPermissions,
        actionStatesRequired: ['readyForSignOff'],
        label: 'Sign-off submission',
    },
    nilReturn: {
        permissions: nilReturnPermissions,
        actionStatesRequired: ['canCreateNilReturn'],
        label: 'Create nil return',
    },
    requestResubmission: {
        permissions: requestResubmissionPermissions,
        actionStatesRequired: ['canRequestResubmission'],
        label: 'Decline',
    },
};

const AwaitingActionMenuItem = ({
    label,
    permissions,
    actionStatesRequired: _actionStatesRequired, // destructuring to avoid passing it to MenuItem, rename to avoid linting error
    ...props
}) => {
    return (
        <RestrictAccess allowPermissions={permissions}>
            <MenuItem {...props}>{label}</MenuItem>
        </RestrictAccess>
    );
};

const AwaitingActionMenu = props => {
    const [actionType, setActionType] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState(null);

    const { provider, actionStates, latestSubmission, collection } = props;
    const { isRefetching } = useProviderStatuses(collection.reference);

    const nextState = useMemo(() => {
        if (actionType === 'approve')
            return latestSubmission?.nextApprovalState;
        return {};
    }, [actionType, latestSubmission]);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        setActionType('');
        setMenuAnchor(null);
    }, [setIsModalOpen, setActionType]);

    const submissionSummaryActions = useSubmissionSummaryActions({
        ...props,
        nextState,
        onSuccess: () => closeModal(),
    });

    const handleAction = useCallback(
        action => {
            setActionType(action);
            setIsModalOpen(true);
        },
        [setActionType, setIsModalOpen],
    );

    const actionFunctions = {
        approve: submissionSummaryActions.approve,
        decline: submissionSummaryActions.requestResubmission,
        nilReturn: submissionSummaryActions.createNilReturn,
        signOff: submissionSummaryActions.signOff,
        requestResubmission: submissionSummaryActions.requestResubmission,
    };

    const actionMessages = {
        approve: 'Are you sure you want to approve this submission?',
        decline: 'Are you sure you want to decline this submission?',
        nilReturn:
            "Are you sure you want to create a 'nil return' for this provider?",
        signOff: 'Are you sure you want to sign off this submission?',
        requestResubmission:
            'Attention: This will remove approval and sign off statuses from this submission. The associated provider will be required to upload a new file to HDP. Do you wish to continue?',
    };
    return (
        <RestrictAccess
            allowPermissions={[
                ...approveAndDeclinePermissions,
                ...signOffPermissions,
                ...nilReturnPermissions,
                ...requestResubmissionPermissions,
            ]}
        >
            <ConfirmationPopup
                isOpen={isModalOpen}
                title="Confirmation required!"
                onCancel={() => {
                    setIsModalOpen(false);
                    setMenuAnchor(null);
                }}
                onConfirm={() => actionFunctions[actionType].mutate()}
                isLoading={
                    actionFunctions[actionType]?.isLoading || isRefetching
                }
            >
                <DialogContentText>
                    {actionMessages[actionType]}
                </DialogContentText>
                {provider?.name && (
                    <Typography noWrap>
                        <strong>Provider:</strong> {provider.name}
                    </Typography>
                )}
                {latestSubmission?.fileHash && (
                    <Typography noWrap>
                        <strong>Transaction ID:</strong>{' '}
                        {latestSubmission.fileHash}
                    </Typography>
                )}
                {collection?.name && (
                    <Typography noWrap>
                        <strong>Collection:</strong> {collection.name}
                    </Typography>
                )}
            </ConfirmationPopup>

            <IconButton
                className={styles.menuIcon}
                disableRipple
                aria-label="More actions"
                aria-controls={menuAnchor ? 'more-actions-menu' : undefined}
                aria-expanded={menuAnchor ? 'true' : undefined}
                aria-haspopup="true"
                onClick={e => setMenuAnchor(e.currentTarget)}
                size="large"
                data-test-id={formatTestId(
                    'more actions',
                    provider.institutionId,
                )}
            >
                <MoreVert />
            </IconButton>
            <Menu
                className="hdp-override--prompt-action"
                id="more-actions-menu"
                anchorEl={menuAnchor}
                open={!!menuAnchor}
                onClose={() => setMenuAnchor(null)}
            >
                {Object.entries(menuItems).map(([id, itemProps]) => {
                    const shouldShowOption =
                        itemProps.actionStatesRequired.some(
                            state => !!actionStates[state],
                        );

                    if (!shouldShowOption) return null;

                    return (
                        <AwaitingActionMenuItem
                            key={`awaiting-action-item-${id}-${provider.institutionId}`}
                            id={id}
                            provider={provider}
                            onClick={() => handleAction(id)}
                            data-test-id={formatTestId(
                                id,
                                provider.institutionId,
                            )}
                            tabIndex={0}
                            {...itemProps}
                        />
                    );
                })}
            </Menu>
        </RestrictAccess>
    );
};

export default AwaitingActionMenu;

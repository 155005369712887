import React, { useCallback } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material';
import { ReactComponent as FileExclamation } from 'assets/icons/file_exclamation.svg';
import { useUserContext } from 'components';
import { DateTime } from 'luxon';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import SubmissionLink from '../../SubmissionSummary/SubmissionLink';
import { requestGracefulFailuresDownload } from '../utils';

import styles from './ovtSubmissionSummaryTable.module.scss';

const formatDate = date => DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm');

const GracefulFailuresDownload = ({ latestSubmission }) => {
    const { isAdmin } = useUserContext();

    if (isAdmin && latestSubmission?.errorFileExists) {
        return (
            <div className={styles.iconWrapper}>
                <Tooltip
                    title="Download error log file"
                    arrow
                    placement={'top'}
                >
                    <button
                        onClick={() =>
                            requestGracefulFailuresDownload(
                                latestSubmission.uuid,
                            )
                        }
                        className="hdp-text-btn--regular"
                        data-test-id={formatTestId(
                            'download file',
                            'graceful failures report',
                        )}
                        aria-label="Download Graceful Failures Report"
                    >
                        <FileExclamation className={styles.icon} />
                    </button>
                </Tooltip>
            </div>
        );
    }
};

const NotStartedSubmissionSummaryTableRow = ({ provider, className }) => {
    return (
        <TableRow hover className={className}>
            <TableCell>{provider?.institutionId}</TableCell>
            <TableCell>{provider?.name}</TableCell>
            <TableCell>Not started</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
        </TableRow>
    );
};
const SubmissionSummaryTableRow = ({
    provider,
    collection,
    latestSubmission,
    isOvt,
    className,
}) => {
    const { statutoryCustomer } = useUserContext();
    const renderRow = () => (
        <TableRow hover className={className}>
            <TableCell>{provider.institutionId}</TableCell>
            <TableCell>{provider.name}</TableCell>
            <TableCell className={styles.multiLinkWrapper}>
                <SubmissionLink
                    collection={collection}
                    providerName={provider.name}
                    institutionId={provider.institutionId}
                    stepPath={statutoryCustomer ? 'reports' : 'upload'}
                    isOvt={isOvt}
                    submission={latestSubmission}
                >
                    {latestSubmission.status.name}
                </SubmissionLink>
                <GracefulFailuresDownload latestSubmission={latestSubmission} />
            </TableCell>
            <TableCell>{formatDate(latestSubmission.uploaded)}</TableCell>
            <TableCell>{latestSubmission.studentCount}</TableCell>
        </TableRow>
    );

    return renderRow();
};

const headCells = [
    { id: 'id', label: 'ID', sortable: false },
    { id: 'provider', label: 'Provider', sortable: false },
    { id: 'current-state', label: 'Current submission state', sortable: false },
    { id: 'last-submission', label: 'Last submission', sortable: true },
    { id: 'student-count', label: 'Student count', sortable: false },
];

const SubmissionSummaryTableHead = ({ orderBy, order, onSort }) => {
    const renderCell = ({ id, label, sortable, customClass }) => {
        const handleClick = id => _event => {
            onSort(id);
        };
        if (!sortable) {
            return <TableCell key={id}>{label}</TableCell>;
        }

        return (
            <TableCell key={id} className={customClass}>
                <TableSortLabel
                    active={id === orderBy}
                    direction={id === orderBy ? order : 'desc'}
                    onClick={handleClick(id)}
                >
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    };

    return (
        <TableHead>
            <TableRow className={styles.header}>
                {headCells.map(renderCell)}
            </TableRow>
        </TableHead>
    );
};

const SubmissionSummaryTableBody = ({
    submissions,
    isOvt,
    pagedSubmissions,
}) => {
    if (submissions.length === 0) {
        return (
            <TableBody>
                <TableRow className={styles.row}>
                    <TableCell align={'center'} colSpan={9}>
                        <Box p={1}>
                            No submissions found that match your criteria
                        </Box>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }

    const renderNormalRow = (submission, index, className) => (
        <SubmissionSummaryTableRow
            className={className}
            key={`ovt-row-${index}`}
            {...submission}
            isOvt={isOvt}
        />
    );

    const renderEmptyRow = (submission, index, className) => (
        <NotStartedSubmissionSummaryTableRow
            className={className}
            key={`ovt-row-${index}`}
            {...submission}
            isOvt={isOvt}
        />
    );

    const renderRow = (submission, index) => {
        const className = styles.row;
        return submission.latestSubmission
            ? renderNormalRow(submission, index, className)
            : renderEmptyRow(submission, index, className);
    };

    return (
        <TableBody className="hdp-override--body-cell">
            {pagedSubmissions.map(renderRow)}
        </TableBody>
    );
};

export const OvtSubmissionSummaryTable = ({
    submissions,
    order,
    orderBy,
    onSort,
    limit,
    setLimit,
    offset,
    setOffset,
    isOvt, // TODO: Isn't this always true for something called OvtSubmissionSummaryTable?
}) => {
    const handleSort = useCallback(
        id => {
            onSort(id);
        },
        [onSort],
    );

    // required for pagination
    const rowCount = submissions.length;

    const handleChangeRowsPerPage = event => {
        const rows = +event.target.value;
        setLimit(rows);
        setOffset(0);
    };

    const handleChangePage = (event, newPage) => {
        setOffset(newPage);
    };

    // prettier-ignore
    const pagedSubmissions = submissions.slice(
        (offset * limit),
        ((offset * limit) + limit)
    );

    return (
        <TableContainer style={{ overflowX: 'auto' }}>
            <Table
                size={'small'}
                data-test-id={formatTestId('table', 'ovt submissions')}
            >
                <SubmissionSummaryTableHead
                    orderBy={orderBy}
                    order={order}
                    onSort={handleSort}
                    isOvt={isOvt}
                />
                <SubmissionSummaryTableBody
                    submissions={submissions}
                    isOvt={isOvt}
                    pagedSubmissions={pagedSubmissions}
                />
            </Table>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={rowCount || 0}
                rowsPerPage={limit}
                page={offset}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                    'data-test-id': 'previousPage',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                    'data-test-id': 'nextPage',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};

OvtSubmissionSummaryTable.displayName = 'OvtSubmissionSummaryTable';

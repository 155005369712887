import React, { SyntheticEvent } from 'react';
import { AutocompleteChangeReason } from '@mui/material';
import AutocompleteDropdown from 'components/AutocompleteDropdown/AutocompleteDropdown';
import { AutocompleteOption } from 'components/AutocompleteDropdown/AutocompleteDropdown';
import { SubmissionStatus } from 'src/types/submission';

import { useSubmissionStates } from '../../queries';
import { SubmissionHydrated } from '../utils';

import styles from './filters.module.scss';

interface StateFilterProps {
    value: string[];
    submissions: SubmissionHydrated[];
    onChange: (
        event: SyntheticEvent,
        value: string[],
        reason: AutocompleteChangeReason,
    ) => void;
}

const getStatesWithCount = (
    submissionStates: SubmissionStatus[] | undefined,
    submissions: SubmissionHydrated[],
    availableStateIds: number[],
) => {
    if (submissionStates === undefined) return [];

    return submissionStates
        .map(submissionState => {
            const state =
                submissionState.id === null
                    ? { ...submissionState, id: 0, code: '__NOT_STARTED' }
                    : submissionState;
            return {
                ...state,
                count: submissions.filter(
                    submission => submission?.state === state.id,
                ).length,
            };
        })
        .filter(state => availableStateIds.includes(state.id));
};

const StateFilter = ({ value, submissions, onChange }: StateFilterProps) => {
    const { data: submissionStates } = useSubmissionStates();

    const availableStateIds = Array.from(
        new Set(submissions.map(submission => submission.state)),
    ) as number[];

    const states: AutocompleteOption[] = getStatesWithCount(
        submissionStates,
        submissions,
        availableStateIds,
    ).map(state => ({
        value: state.id.toString(),
        label: `${state.name} (${state.count})`,
    }));

    return (
        <div className={styles.filterContainer}>
            <AutocompleteDropdown
                id="state-filter"
                placeholder="Filter by state"
                options={states}
                value={value}
                onChange={onChange}
                label="Filter by State"
            />
        </div>
    );
};

export default StateFilter;
